import React, {useCallback, useState, useEffect} from "react";
import {Backdrop, Button, CircularProgress, FormGroup, MenuItem, Select, Box} from '@mui/material';
import {createUpdateTranscript, deleteTranscript, uploadS3, fetchLanguageMatrix} from "../backend/api"
import { CognitoUserPool, } from 'amazon-cognito-identity-js';
import ErrorModal from "./DefaultModal";
import { useDropzone } from "react-dropzone";
import { formatBytes } from "../backend/util";
import AudioFileIcon from '@mui/icons-material/AudioFile';
//import { makeStyles } from "@mui/styles";
import languages from './language_matrix.json'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const NewTranscriptForm = () => {
    const serviceMessage1 = "Auto detect and multiple language selection does NOT work with Vietnamese"
    const serviceMessage2 = "Text files do not require language and service selection"
    const createButtonCTA1 = "Create Transcript"
    const createButtonCTA2 = "Convert Script to Transcript"
    const [service, setService] = useState("aws")
    const [language, setLanguage] = useState(["langselect"])
    const [languageList, setLanguageList] = useState([])
    const [pstate, setPstate] = useState(true)
    const [filesToUpload, setFilesToUpload] = useState([])
    const [defaultModalOpen, setDefaultModalOpen] = useState(false)
    const [defaultModalMsg, setDefaultModalMsg] = useState('')
    const [saveOpen, setSaveOpen] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [languageMatrix, setLanguageMatrix] = useState([])
    const [, updateState] = React.useState();
    const [showAddButton, setShowAddButton] = useState("hidden")
    const [isTextFile, setIsTextFile] = useState(false)
    const [serviceMessage, setServiceMessage] = useState(serviceMessage1)
    const [createButtonCTA, setCreateButtonCTA] = useState(createButtonCTA1)
    const langOptions = []

    useEffect(() => {
        async () => {
            const lmatrix = await fetchLanguageMatrix()
            setLanguageMatrix(lmatrix)
        }
    })

    // const useStyles = makeStyles({
    //     readOnlySelect: {
    //       width: 300,
    //       "&.Mui-disabled option": {
    //         color: "black"
    //       }
    //     }
    //   });

    const forceUpdate = React.useCallback(() => updateState({}), []);

    /*
    * TODO: Migrate to the /language_matrix endpoint to get the supported languages
    */
    const langs = languages.languages

    const lang_keys = Object.keys(langs)

    const getLangOptions = (service) => {
        lang_keys.forEach((lang) => {
            langOptions.push({"language": lang})
        })
    }

    getLangOptions(service)

    const handleDefaultModalClose = () => { setDefaultModalOpen(false) }

    const handleServiceChange = (event) => {
        setService(event.target.value);
        getLangOptions(service)
    }

    const handleLanguageChange = (event) => {
        if (event.target.value === "langselect") {
            setShowAddButton("hidden")
        } else {
            setShowAddButton("")
        }
        setLanguage(event.target.value);
    }

    /**
     * Return the recommended service for the language.
     */
    const getLanguageService = (lang) => {
        const langObject = langs[lang]
        const keys = Object.keys(langObject)
        var newService = service
        keys.forEach((serviceItem) => {
            const serviceKeys = Object.keys(langObject[serviceItem])
            console.log(serviceKeys)
            if (serviceKeys.includes("recommended")) {
                newService = serviceItem
            }
        })

        console.log("what?")

        return newService
    }

    const updateActiveLanguages = () => {
        console.log(language)
        if (!languageList.includes(language) && language != "langselect") {
            if (service in langs[language]) {
                setLanguageList([...languageList, language])
                setShowAddButton("hidden")
                setLanguage("langselect")
            } else {
                if (languageList.length === 0) {
                    const langService = getLanguageService(language)
                    console.log(langService)
                    setLanguageList([...languageList, language])
                    setService(langService)
                    setShowAddButton("hidden")
                    forceUpdate()
                } else if (languageList.length !== 0) {
                    setDefaultModalMsg("This language cannot be used in this combination")
                    setDefaultModalOpen(true)
                } else {
                    setDefaultModalMsg("That language is NOT supported by the transcription service")
                    setDefaultModalOpen(true)
                }
            }
        }
    }

    const deleteActiveLanguage = (activeLanguage) => {
        setLanguageList(languageList.filter((item) => item !== activeLanguage.language))
    }

    const onDrop  = useCallback((acceptedFiles, rejectedFiles) => {
        if(filesToUpload.length > 0) {
            return;
        } else {
            acceptedFiles.map((file) => {
                setFilesToUpload([...filesToUpload, ...acceptedFiles])
            }, [filesToUpload])
            console.log(acceptedFiles)
            const file_ext = acceptedFiles[0]['path'].split('.').pop()
            if (acceptedFiles[0]['type'] === "text/plain" || file_ext === 'srt' || file_ext === 'vtt') {
                setIsTextFile(true)
                setServiceMessage(serviceMessage2)
                setCreateButtonCTA(createButtonCTA2)
            }
        }
    })

    const removeFile = (file) => {
        const newFiles = [...filesToUpload]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFilesToUpload(newFiles)
        setIsTextFile(false)
        setServiceMessage(serviceMessage1)
        setCreateButtonCTA(createButtonCTA1)
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({ onDrop,
            multiple: false,
            maxFiles: 1,
            accept: {
                'audio/mpeg': ['.mp3', '.MP3'],
                'audio/wav': ['.wav', '.WAV'],
                'text/vtt': ['.vtt', '.webvtt'],
                'text/plain': ['.srt', '.txt']
            },
    });

    const handleForm = async () => {

        const userPool = new CognitoUserPool({
            UserPoolId: process.env.REACT_APP_USER_POOL_ID,
            ClientId: process.env.REACT_APP_CLIENT_ID
        });

        var currentUser = userPool.getCurrentUser();

        if (filesToUpload === null || filesToUpload.length === 0) {
            setDefaultModalMsg("Please add a file to be uploaded")
            setDefaultModalOpen(true)
            return
        }

        const languageCodes = []

        if (isTextFile === false && (languageList === null || languageList.length === 0)) {
            setDefaultModalMsg("Please select a language")
            setDefaultModalOpen(true)
            return
        }

        languageList.forEach((langLabel) => {
            console.log(langs[langLabel][service])
            languageCodes.push(langs[langLabel][service]["code"])
        })

        const transcriptObject = {
            "Type": "CREATE",
            "transcriptObject": {
                "Title": filesToUpload[0]['name'],
                "Language": languageCodes,
                "Service": service,
            },
            "UserId": currentUser["username"],
        }

        const response = await createUpdateTranscript(transcriptObject);

        const handleFileError = (error) => {
            console.log(error)
            setDefaultModalMsg("File upload failed, please try again later")
            setDefaultModalOpen(true)
            deleteTranscript(response["id"])
            setSaveOpen(false)
        }

        if (response["type"] !== "ERROR") {
            const mediaFile = response['id']+"_"+filesToUpload[0]['name'].replaceAll(" ", "_")
            uploadS3(filesToUpload[0], response['id'], setSaveOpen, function(percent) {
                setUploadProgress(percent)
            })
                .then(async () => {
                    const updateTranscriptObject = {
                        "Type": "UPDATE",
                        "transcriptId": response['id'],
                        "transcriptObject": {
                            "MediaFile": mediaFile,
                            "id": response['id']
                        },
                        "UserId": currentUser["username"]
                    }
                    const updateMediaFile = await createUpdateTranscript(updateTranscriptObject)

                    setSaveOpen(false)
                    setDefaultModalMsg("The Transcript has been registered and should be available shortly")
                    setDefaultModalOpen(true)
                    setFilesToUpload([])
                    setLanguageList([])
                    setLanguage("langselect")
                    setIsTextFile(false)
                    setServiceMessage(serviceMessage1)
                    setCreateButtonCTA(createButtonCTA1)
                })
                .catch(error => handleFileError(error))

            if (pstate === true) {
                setPstate(false);
            }
            else{
                setPstate(true);
            }

        } else {
            setDefaultModalMsg(response["message"])
            setFilesToUpload([])
            setDefaultModalOpen(true)
        }
    }

    //const styleClasses = useStyles()

    return (
    <div>
        <FormGroup
        sx={{
            width: 1,
        }}>
            <div className="fileUploader" {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop media here ...</p>
                ):(
                    <p>Drag and drop media file(s) here, or click to select files</p>
                )}
                </div>
                <ul className="fileList">
                    {filesToUpload !== null && filesToUpload.length > 0 &&
                        filesToUpload.map((media, index) => (
                            <li><span onClick={() => removeFile(media)}><AudioFileIcon fontSize="large"></AudioFileIcon>{media.name} - {formatBytes(media.size)}</span></li>
                        ))}
                </ul>
            <div>
                <div className="mt-2">
                        *Note: {serviceMessage}
                    <Select
                        //className={styleClasses.readOnlySelect}
                        label="Language"
                        //autoWidth
                        //multiple
                        disabled={isTextFile}
                        value={language}
                        defaultValue={["en-AU"]}
                        sx={{
                            width: 1,
                            color: "white",
                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderRadius: "10px",
                            marginTop: "10px",
                            "&.Mui-disabled": {
                                background: "#eaeaea",
                                color: "#c0c0c0"
                            }}}
                        onChange={handleLanguageChange}
                    >
                        <MenuItem value="langselect">Select language</MenuItem>
                            {
                                langOptions.map((entry) => {
                                    return (<MenuItem value={entry.language}>{entry.language}</MenuItem>)
                                }
                            )}
                    </Select>
                    <div className={showAddButton}>
                        <Button
                            variant="contained"
                            sx={{marginTop: "5px"}}
                            onClick={updateActiveLanguages}>
                                Add selected language
                        </Button>
                    </div>
                    <div>
                        {
                            languageList.map((language) => {
                                // console.log(language)
                                return (
                                    <Box
                                        sx={{
                                            color: "white",
                                            width: 1,
                                            fontSize: 16,
                                            marginTop: "10px",
                                            padding: "10px",
                                            cursor: 'pointer',
                                            border:'solid',
                                        }}
                                        className="flex">
                                            <div className="flex">{language}</div>
                                            <div className="flex">
                                                <DeleteForeverIcon
                                                    sx={{cursor: 'pointer'}}
                                                    fontSize="large"
                                                    titleAccess="Delete"
                                                    onClick={() => deleteActiveLanguage({language})}>
                                                </DeleteForeverIcon>
                                            </div>
                                    </Box>
                                )
                            })
                        }
                    </div>
                </div>
                <div>
                    <Select
                        label="Transcription Service to use"
                        value={service}
                        defaultValue={"aws"}
                        disabled={isTextFile}
                        sx={{
                            width: 1,
                            color: "white",
                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderRadius: "10px",
                            marginTop: "10px",
                            "&.Mui-disabled": {
                                background: "#eaeaea",
                                color: "#c0c0c0"
                            }
                        }}
                        onChange={handleServiceChange}>
                            <MenuItem value={"aws"}>Amazon (AWS)</MenuItem>
                            <MenuItem value={"google"}>Google - Transcriptions do not include speakers</MenuItem>
                    </Select>
                </div>
            </div>
            <div className="my-5 w-full">
                <Button variant="contained" className="my-8" color="primary" component="span" onClick={handleForm}>{createButtonCTA}</Button>
            </div>
        </FormGroup>
        <Backdrop
            open={saveOpen}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
            <h1 className="ml-5">Uploading your media now! {Math.floor(uploadProgress)}%</h1>
        </Backdrop>
        <ErrorModal handleClose={handleDefaultModalClose} open={defaultModalOpen} errorMsg={defaultModalMsg}></ErrorModal>
    </div>
    )
}

export default NewTranscriptForm;
