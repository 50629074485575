import React from "react";
import { logout } from "../backend/authenticate";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import LogoutIcon from '@mui/icons-material/Logout';

const SideNav = () => {

    return(
        <div>
        <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
            <span className="sr-only">Open sidebar</span>
            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
            </svg>
        </button>

    <aside id="default-sidebar" className="fixed top-0 left-0 z-40 w-60 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
        <div className="h-100 px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 sidenav rounded-2xl mb-3 ">
            <ul className="space-y-2 font-medium">
                <li>
                    <a href="#" className="flex items-center p-2 group">
                        <SpaceDashboardIcon />
                        <span className="ms-3"><a href="/dashboard">Dashboard - {process.env.REACT_APP_RELEASE_ID}</a></span>
                    </a>
                </li>
            </ul>
        </div>
        <div className="h-3/4 px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 sidenav rounded-2xl flex flex-col">
            <div>
                <ul>
                    <li className="pb-4 flex justify-start">
                        <a href="/languages" className="flex" rel="noreferrer">
                            <div className="pr-3"><img src="/images/sbs_ai_translations_enabled.svg" className="sidenav-icon"></img></div>
                            <div className="w-full flex">
                                <div className="pr-3">AI models comparison</div>
                            </div>
                        </a>
                    </li>
                    <li className="pb-4 flex justify-start">
                        <a href="https://sbsprod1.service-now.com/selfservice" target="_blank" className="flex" rel="noreferrer">
                            <div className="pr-3"><img src="/images/bug.svg" className="sidenav-icon"></img></div>
                            <div className="w-full flex">
                                <div className="pr-3">Report Bugs</div>
                                <img className="sidenav-icon" src="/images/external_link.svg" />
                            </div>
                        </a>
                    </li>
                    <li className="pb-4 flex justify-start">
                        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=EQp-m4YyKE6nPfyomtnpFbfp6LfeJFxDvY_EeT7mH7FUNkJFNlAwWVFGSUNWRFFCUFdLM01ENjBSMS4u" target="_blank" className="flex" rel="noreferrer">
                        <div className="pr-3"><img src="/images/conversation.svg" className="sidenav-icon"></img></div>
                            <div className="w-full flex">
                                <div className="pr-3">Send Feedback</div>
                                <img className="sidenav-icon" src="/images/external_link.svg" />
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <hr />
            <div className="flex flex-col h-full bottom-0">
                <ul className="pt-5">
                    <li className="pb-4 flex justify-start">
                        <a href="https://myapps.microsoft.com/signin/e80e66ec-ca82-4e95-80f4-e8bc9d876492?tenantId=9b7e0a11-3286-4e28-a73d-fca89ad9e915&RelayState=https://sbsu.litmos.com/course/16685814" target="_blank" className="flex" rel="noreferrer">
                            <div className="pr-3"><img src="/images/book.svg" className="sidenav-icon"></img></div>
                            <div className="pr-3">Onboarding</div>
                            <img className="sidenav-icon" src="/images/external_link.svg" />
                        </a>
                    </li>
                    <li className="pb-4 flex justify-start">
                        <a href="https://myintranet.sbs.com.au/ai/" target="_blank" className="flex" rel="noreferrer">
                            <div className="pr-3"><img src="/images/info.svg" className="sidenav-icon"></img></div>
                            <div className="pr-3">AI at SBS</div>
                            <img className="sidenav-icon" src="/images/external_link.svg" />
                        </a>
                    </li>
                    <li className="pb-3">
                        <a href="#" className="flex items-center p-2 group">
                            <LogoutIcon />
                            <span className="ms-3"><a href="#" onClick={logout}>Logout</a></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </aside>
</div>
)
};

export default SideNav;
