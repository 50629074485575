import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import SideNav from '../components/SideNav';
import { getCurrentUserSession, getCurrentUser } from '../backend/api';
import jsonData from '../components/language_matrix';
const languageMatrix = JSON.parse(JSON.stringify(jsonData));
import { GridColDef, DataGrid, GridCellParams } from '@mui/x-data-grid';

const Languages = () => {

  const Navigate = useNavigate();

  useEffect(()=>{
    if(getCurrentUserSession() === false) {
      Navigate('/');
    } else {
      const user = getCurrentUser()
      const username = user["username"];
    }
  },[Navigate]);

  const rows = Object.keys(languageMatrix.languages)
  .filter(lang => lang !== 'Auto Detect')
  .map((lang, index) => {
    let recommendation
    if (languageMatrix.languages[lang].aws?.recommended) {
      recommendation = 'AWS'
    }
    else if (languageMatrix.languages[lang].google?.recommended) {
      recommendation = 'Google'
    }

    return {
      id: index,
      lang,
      awsConfidence: languageMatrix.languages[lang].aws?.confidence,
      awsConfidenceLevel: languageMatrix.languages[lang].aws?.confidenceLevel,
      googleConfidence: languageMatrix.languages[lang].google?.confidence,
      googleConfidenceLevel: languageMatrix.languages[lang].google?.confidenceLevel,
      recommendation,
    }
  })

  const columns = [
    { field: 'lang', headerName: 'Language', width: 250 },
    {
      field: 'awsConfidence',
      headerName: 'Amazon (AWS)',
      cellClassName: (params) => {
        if (!params.value) return
        return params.row.awsConfidenceLevel
     },
    },
    { field: 'googleConfidence', headerName: 'Google',
      cellClassName: (params) => {
        if (!params.value) return
        return params.row.googleConfidenceLevel
     },
    },
    { field: 'recommendation', headerName: 'Recommendation', },
  ];

  const gridStyle = {
    '& .MuiButtonBase-root, & .MuiDataGrid-cell, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, ': {
      color: 'white',
    },
    '& .MuiButtonBase-root-MuiIconButton-root': {
      color: 'white !important',
    },
    '& .MuiDataGrid-container--top [role=row]': {
      color: 'white',
      backgroundColor: 'black',
    },
    '& .MuiDataGrid-booleanCell[data-value="true"]': {
      color: 'lime',
    },
    '& .MuiDataGrid-booleanCell[data-value="false"]': {
      color: 'red',
    },
    '& .high': {
      backgroundColor: 'lime',
      opacity: 0.85,
      color: '#1a1a1a',
      fontWeight: '600',
    },
    '& .mid': {
      backgroundColor: 'yellow',
      opacity: 0.85,
      color: '#1a1a1a',
      fontWeight: '600',
    },
    '& .low': {
      backgroundColor: 'red',
      opacity: 0.85,
      color: '#1a1a1a',
      fontWeight: '600',
    },
  }

  return (
    <div>
      <div className="h-screen flex justify-center">
        <div className="grid grid-cols-12">
          <div className="col-span-2">
            <SideNav/>
          </div>
          <div className="col-span-1"></div>
          <div className="justify-center col-span-8 h-[90vh] w-full py-12">
            <DataGrid
              hideFooter={true}
              rows={rows}
              columns={columns}
              sx={gridStyle}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Languages
